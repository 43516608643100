<template lang="pug">
  .studio
    h1 STUDIO

</template>

<script>
export default {

}
</script>

<style lang="stylus">
  .studio
    padding 20px 20px 50px
    h1
      text-align center
</style>
